.header {
    position: absolute;
    width: 100%;
    top:0;
    height: 2rem;
    background-color: brown;
}

.site-title {
	width: 100%;
	text-align: center;
	padding: 8px 0;
	color: #ffffff;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}