.content {
    position: absolute;
    width: 100%;
    top: 2rem;
    bottom: 3rem;
    overflow-y: scroll;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.content-lower {
	top: 7rem;
}