.player {
    position: absolute;
    width: 100%;
    height: 5rem;
    top:2rem;
    padding: 5px;
    background-color: #000000;
}

.player-header {
    position: relative;
}

#timeIndicator {
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: small;
    color: #ffffff;
}

input[type=range] {
    margin: 1rem 0;
    width: 100%;
    -webkit-appearance: none; /* Webkit */
    -moz-appearance: none; /* FF */
    -ms-appearance: none; /* Edge */
    appearance: none; /* Future */   
}

input[type=range]:focus {
    outline: none;
}
  
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
  
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none; /* Webkit */
    -moz-appearance: none; /* FF */
    -ms-appearance: none; /* Edge */
    appearance: none; /* Future */
    margin-top: -14px;
}
  
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
}
  
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
  
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
  
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
  
input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
  
input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
  
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
  
input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
}

input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}