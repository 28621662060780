.nav-btn {
    height: 100%;
    width: 25%;
    border: none;
    background-color: brown; 
    color: white; 
    cursor: pointer;
}
  
.nav-btn-2 {
    width: 50%;
}
  
.nav-btn-disabled {
    color: gray;
    cursor: not-allowed;
}