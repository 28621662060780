table {
	border-collapse: collapse;
	width: 100%;
	padding: 5px 2px;
}

table, td {
	border: 0;
}

td {
	vertical-align: middle;
	padding: 5px 0;
}