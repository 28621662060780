/* Reset */
@import-normalize; /* bring in normalize.css styles */

/* Change all elements to use boorder-box */
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}